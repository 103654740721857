import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const backgroundHolderPion = css`
  min-height: 100vh;
  min-width: 100%;
  background-color: ${Colours('offBlack', 300)};
  position: relative;
  overflow: hidden;
`

export const iconAnchor = css`
  position: absolute;
  left: calc(100dvw / 2);
  top: calc(100dvh / 2);
  width: 100vw;
  height: 100vh;
`
