import PropTypes from 'prop-types'

import SafeImage from '@components/safe_image'
import { withAssetPrefix } from '@helpers/assets'

import * as styles from './styles'

const PionBackgroundIcon = ({
  iconName,
  top,
  left,
  height,
  width,
  rotation,
  opacity
}) => {
  return (
    <div css={styles.image(top, left, rotation, opacity)}>
      <SafeImage
        alt=''
        width={width}
        height={height}
        src={withAssetPrefix(`images/pion_icons/${iconName}.png`)}
      />
    </div>
  )
}

PionBackgroundIcon.defaultProps = {
  rotation: 0,
  opacity: 1
}

PionBackgroundIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  rotation: PropTypes.number,
  opacity: PropTypes.number
}

export default PionBackgroundIcon
