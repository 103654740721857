import PropTypes from 'prop-types'

import BannerPionRebrand from '@components/banner_pion_rebrand'
import LoginBackground from '@components/login_background'
import SafeImage from '@components/safe_image'
import { withAssetPrefix } from '@helpers/assets'

import { PION_LOGO_ALT_TEXT } from './constants'
import * as styles from './styles'

const LoginPage = ({ children, preventBackgroundAnimation }) => {
  const renderLogo = () => {
    return (
      <div css={styles.logo}>
        <SafeImage
          height={51}
          width={200}
          alt={PION_LOGO_ALT_TEXT}
          src={withAssetPrefix('images/pion-branded-logo-centered.svg')}
        />
      </div>
    )
  }

  return (
    <LoginBackground preventBackgroundAnimation={preventBackgroundAnimation}>
      <div css={styles.content}>
        {renderLogo()}
        <BannerPionRebrand />
        {children}
      </div>
    </LoginBackground>
  )
}

LoginPage.defaultProps = {
  preventBackgroundAnimation: false
}

LoginPage.propTypes = {
  children: PropTypes.node.isRequired,
  preventBackgroundAnimation: PropTypes.bool
}

export default LoginPage
