import Typography from '@thebeansgroup/ui/typography'

const BannerPionRebrand = (): React.ReactElement => {
  return (
    <div className='flex z-10 bg-success-green-200 w-60 mb-5 rounded-[8px] border border-success-green-300 p-4 justify-center md:w-[440px]'>
      <Typography
        variant='footnote'
        colour='success-green-700'
      >
        <b>We are now known as Pion! </b>
        <span>Visit our website to </span>
        <a
          className='underline text-success-green-700'
          target='_blank'
          href='https://wearepion.com'
        >
          learn more
        </a>
      </Typography>
    </div>
  )
}

export default BannerPionRebrand
