import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import React from 'react'

function LoginAnimation({ children }) {
  const fadeOut = { opacity: 0 }
  const fadeIn = { opacity: 1 }

  const transition = { duration: 0.4, ease: 'easeOut' }

  const styles = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  }

  return (
    <motion.div
      initial={fadeOut}
      animate={fadeIn}
      exit={fadeOut}
      transition={transition}
      style={styles}
    >
      {children}
    </motion.div>
  )
}

LoginAnimation.propTypes = {
  children: PropTypes.node.isRequired
}

export default LoginAnimation
