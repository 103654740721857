export const ICONS = [
  {
    iconName: 'speaker',
    top: -500,
    left: -500,
    rotation: -15,
    width: 200,
    height: 200
  },
  {
    iconName: 'green-lightning',
    top: 200,
    left: 0,
    rotation: -30,
    width: 150,
    height: 250
  },
  {
    iconName: 'blue-spring',
    top: 200,
    left: 600,
    rotation: -15,
    width: 125,
    height: 150,
    opacity: 0.5
  },
  {
    iconName: 'yellow-curve',
    top: -550,
    left: 350,
    rotation: -15,
    width: 260,
    height: 120
  },
  {
    iconName: 'finger-point',
    top: -50,
    left: -450,
    rotation: -10,
    width: 260,
    height: 260
  },
  {
    iconName: 'purple-curve',
    top: -150,
    left: 150,
    rotation: 180,
    width: 260,
    height: 130,
    opacity: 0.6
  },
  {
    iconName: 'blue-dot-line',
    top: -250,
    left: -800,
    rotation: 0,
    width: 260,
    height: 130,
    opacity: 0.3
  },
  {
    iconName: 'brain',
    top: -250,
    left: 600,
    rotation: 10,
    width: 200,
    height: 160
  },
  {
    iconName: 'laptop',
    top: 250,
    left: -700,
    rotation: 315,
    width: 200,
    height: 200
  }
]
