import PropTypes from 'prop-types'

import PionBackgroundIcon from '@components/pion_background_icon'

import { ICONS } from './constants'
import * as styles from './styles'

const LoginBackground = ({ children, preventBackgroundAnimation }) => {
  return (
    <div
      data-testid='background-holder'
      css={styles.backgroundHolderPion}
    >
      <div css={styles.iconAnchor}>
        {ICONS.map((icon, index) => {
          return (
            <PionBackgroundIcon
              key={index}
              iconName={icon.iconName}
              top={icon.top}
              left={icon.left}
              rotation={icon.rotation}
              width={icon.width}
              height={icon.height}
              opacity={icon.opacity}
            />
          )
        })}
      </div>
      {children}
    </div>
  )
}

LoginBackground.defaultProps = {
  preventBackgroundAnimation: false
}

LoginBackground.propTypes = {
  children: PropTypes.node.isRequired,
  preventBackgroundAnimation: PropTypes.bool
}

export default LoginBackground
