import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const inputParent = css`
  position: relative;
  padding-bottom: 20px;
`

export const input = css`
  width: 100%;
  border-radius: 10px;
  background-color: ${Colours('white')};
  border: 1px solid ${Colours('neutral', 100)};
  padding: 12px;
  height: auto;

  &:focus {
    background-color: ${Colours('white')};
    border: 1px solid ${Colours('colbalt', 300)};
  }
`

export const inputError = css`
  border-color: ${Colours('cantaloupe', 400)};
`

export const error = css`
  position: absolute;
  font-size: 12px;
  line-height: 12px;
  bottom: 0;
  color: ${Colours('alertRed', 300)};
`
