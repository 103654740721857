import { css } from '@emotion/react'

export const image = (top, left, rotation, opacity) => css`
  position: absolute;
  display: inline-flex;
  transform: rotate(${rotation}deg);
  top: ${top}px;
  left: ${left}px;
  opacity: ${opacity};
`
