import PropTypes from 'prop-types'

import Typography from '@thebeansgroup/ui/typography'

import * as styles from './styles'

const LoginInput = ({
  type,
  name,
  id,
  value,
  onChange,
  error,
  handleKeyDown,
  placeholder,
  maxLength
}) => {
  const handleChange = (event) => {
    onChange(event.target.value)
  }

  const renderLabel = () => {
    return (
      <label htmlFor={id}>
        <Typography
          variant='footnote'
          colour='off-black-300'
        >
          {name}
        </Typography>
      </label>
    )
  }

  const renderError = () => {
    return <span css={styles.error}>{error}</span>
  }

  return (
    <div css={styles.inputParent}>
      {renderLabel()}
      <input
        id={id}
        css={[styles.input, error && styles.inputError]}
        value={value}
        type={type}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        maxLength={maxLength}
      />
      {renderError()}
    </div>
  )
}

LoginInput.defaultProps = {
  error: '',
  handleKeyDown: null,
  placeholder: '',
  maxLength: null
}

LoginInput.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  handleKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number
}

export default LoginInput
