import { css } from '@emotion/react'

export const content = css`
  display: flex;
  flex-direction: column;
  padding-top: 58px;
  align-items: center;
`

export const logo = css`
  position: relative;
  z-index: 5;
  margin-bottom: 20px;
`
